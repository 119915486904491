export const getPaymentParams = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const amount = searchParams?.get('amount')
    const currency = searchParams?.get('currency')
    const fullname = searchParams?.get('fullname')
    const email = searchParams?.get('email')
    const voucherId = searchParams?.get('voucherId')
    const merchant = searchParams?.get('user')
    const bvn = searchParams?.get('bvn')
    const voucherType = searchParams?.get('voucherType')
    return {
        bvn,
        email,
        amount,
        currency,
        fullname,
        voucherId,
        merchant,
        voucherType
    }
}

export const getAuthorizationDetails = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const user = searchParams.get('user')
    const key = searchParams.get('key')
    return {
        user, key
    }
}

export const getCallBack = () => {
    const params: URLSearchParams = new URLSearchParams(window?.location?.search)
    const callback = params?.get('callback')
    return callback

}