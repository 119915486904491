import React from "react";
import { CreateVoucherDataNGN, CreateVoucherDataUSD } from "../api/models/voucher";
export interface Store { state?: CreateVoucherDataNGN | CreateVoucherDataUSD, setState?: Function }
const store: Store = {
}

const VoucherContext = React.createContext(store);
VoucherContext.displayName = "Voucher Context";

export default VoucherContext;
