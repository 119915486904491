import React from 'react';
import { useNavigate } from 'react-router-dom';
import Warning from '../assets/error-icon.webp';
import { getPaymentParams } from '../utils';
import '../Expired/Expired.scss';
import './Error.scss';

export const Error = () => {
  const navigate = useNavigate();
  const { voucherId } = getPaymentParams();

  const navigateToRetryPayment = () => {
    const isFromPaymentStatusRoute = `${document.referrer}`.includes(
      `/payment/${voucherId}`
    );

    if (isFromPaymentStatusRoute) {
      navigate(`/payment/${voucherId}`);
    } else {
      navigate(`/voucher/pay${window?.location?.search}`);
    }
  };

  return (
    <div className="PaymentCardContent">
      <div className="payment-header animate__animated animate__fadeIn">
        <img
          src={Warning}
          alt="expired"
          className="payment-header__warning error-header__warning"
          style={{ height: 233, width: 'auto' }}
        />
        <div className="expired-payment-header-title" data-cy="error">
          Something went wrong
        </div>
        <div className="expired-payment-header-content">
          Please refresh and try again
        </div>
      </div>

      <button
        onClick={navigateToRetryPayment}
        data-cy="retry"
        className="btn__grey animate__animated animate__fadeInUp td-300"
        style={{ marginBottom: 15 }}
      >
        Refresh
      </button>
    </div>
  );
};

export default Error;
