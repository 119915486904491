import React, { useContext } from "react";
import { LoginResponse } from "../api/fusionApi";

export interface Store { state?: LoginResponse, setState?: Function }
const store: Store = {
}

const InvoiceContext = React.createContext(store);
InvoiceContext.displayName = "Invoice Context";
export const useInvoiceContext = () => useContext(InvoiceContext)
export default InvoiceContext;