import React, { ReactNode, useEffect, useState } from 'react';
import InvoiceContext from './invoiceContext';
import { LoginResponse } from '../api/fusionApi';
import { useNavigate } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';

const InvoiceProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [state, setState] = useState<LoginResponse | undefined>();

  if (state?.user?.active) {
    const userData = state.user;
    Bugsnag.setUser(userData?.username, userData?.email, userData?.fullName);
  }

  const store = localStorage.getItem('invoice');

  useEffect(() => {
    if (state?.user?.active) {
      const currentPath = window.location.pathname;
      const allowedPaths = ['/invoice/success', '/invoice/create'];

      if (!allowedPaths.includes(currentPath)) {
        navigate('/invoice/create');
      }
    } else {
      navigate('/invoice/signin');
    }
  }, [state?.user?.active, navigate]);

  useEffect(() => {
    if (store) {
      const invoice: LoginResponse = JSON.parse(store);
      setState(invoice);
    }
  }, [store]);

  return (
    <InvoiceContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  );
};

export default InvoiceProvider;
