import React, { Suspense, lazy } from 'react';

import { ErrorBoundary } from './components/Error';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import VoucherProvider from './store/voucherProvider';
import InvoiceProvider from './store/invoiceProvider';

const Pay = lazy(() => import('./components/Pay'));
const Footer = lazy(() => import('./components/Footer'));
const Header = lazy(() => import('./components/Header'));
const Expired = lazy(() => import('./components/Expired'));
const Success = lazy(() => import('./components/Success'));
const Error = lazy(() => import('./components/Error/Error'));
const NotFound = lazy(() => import('./components/Error/404'));
const WrongAmount = lazy(() => import('./components/WrongAmount'));
const Landingpage = lazy(() => import('./components/LandingPage'));
const Payment = lazy(() => import('./components/Payment'));
const Spinner = lazy(() => import('./components/spinner'));

//invoice
const InvoiceHeader = lazy(() => import('./components/Invoice/Header'));
const SignIn = lazy(() => import('./components/Invoice/SignIn'));
const CreateInvoice = lazy(
  () => import('./components/Invoice/CreateInvoice/index')
);
const InvoiceSuccess = lazy(() => import('./components/Invoice/Success'));

const Payments = () => {
  return (
    <VoucherProvider>
      <div className="mainApp animate__animated animate__fadeIn td-200">
        <Header />
        <Routes>
          <Route path="/pay" element={<Pay />} />
          <Route path="/wrongamount" element={<WrongAmount />} />
          <Route path="/expired" element={<Expired />} />
          <Route path="/success" element={<Success />} />
        </Routes>
        <Footer />
      </div>
    </VoucherProvider>
  );
};

const Invoice = () => {
  return (
    <InvoiceProvider>
      <div className="mainApp animate__animated animate__fadeIn td-200">
        <InvoiceHeader />

        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/create" element={<CreateInvoice />} />
          <Route path="/success" element={<InvoiceSuccess />} />
        </Routes>
      </div>
    </InvoiceProvider>
  );
};

const App = () => {
  return (
    <>
      <BrowserRouter>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route path="/" element={<Landingpage />} />
              <Route path="/error" element={<Error />} />
              <Route path="/payment/:id" element={<Payment />} />
              <Route path="/voucher/*" element={<Payments />} />
              <Route path="/invoice/*" element={<Invoice />} />
              <Route path="/:version/voucher/*" element={<Payments />} />
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </BrowserRouter>
    </>
  );
};

export default App;
