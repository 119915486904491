import React, { ReactNode } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, {
  BugsnagPluginReactResult,
} from '@bugsnag/plugin-react';
import { Error as ErrorComponent } from './Error';
import './style.scss';

const searchParams = new URLSearchParams(window.location.search);
const senderEmail: string = searchParams?.get('senderEmail') || '';

Bugsnag.start({
  apiKey: `${process.env.REACT_APP_BUGSNAG_API_KEY}`,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging', 'alpha'],
  releaseStage: process.env.REACT_APP_ENVIRONMENT,
  user: {
    email: senderEmail,
  },
});

interface Props {
  children?: ReactNode;
}
// export const ErrorBoundary =
//   Bugsnag.getPlugin('react')?.createErrorBoundary(React);
const plugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult;
export const ErrorBoundary = plugin.createErrorBoundary(React);

const ErrorBoundaryFallback = () => (
  <div className="Error__boundaryContainer">
    <div className="Error__boundary">
      <ErrorComponent />
    </div>
  </div>
);

const ErrorBoundaryHandler = ({ children }: Props) => {
  return ErrorBoundary ? (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      {children}
    </ErrorBoundary>
  ) : (
    children
  );
};

export default ErrorBoundaryHandler;
